export const paymentTypes = {
  STRIPE: 'ST',
  PAYPAL: 'PP'
}

export const paymentProvider = {
  [paymentTypes.STRIPE]: 7,
  [paymentTypes.PAYPAL]: 1
}

export const customerStatus = {
  CAPTURED: 2,
  PENDING: 1,
  DECLINED: -2
}

export const orderStatus = {
  CAPTURED: 2,
  AUTHORIZED: 1,
  DECLINED: -2
}

export const shippingMethods = {
  USPS_PRIORITY: 11,
  USPS_FIRST_CLASS: 12,
  USPS_INTERNATIONAL: 21,
  USPS_GROUND_ADVANTAGE: 501
}

export const shippingMethodDisplayNames = {
  [shippingMethods.USPS_PRIORITY]: 'USPS Priority',
  [shippingMethods.USPS_FIRST_CLASS]: 'USPS First Class',
  [shippingMethods.USPS_INTERNATIONAL]: 'USPS International',
  [shippingMethods.USPS_GROUND_ADVANTAGE]: 'USPS Ground Advantage'
}

export const shippingMethodDataNames = {
  [shippingMethods.USPS_PRIORITY]: 'Priority',
  [shippingMethods.USPS_FIRST_CLASS]: 'FirstClass',
  [shippingMethods.USPS_INTERNATIONAL]: 'PriorityMailInternational',
  [shippingMethods.USPS_GROUND_ADVANTAGE]: 'GroundAdvantage'
}

export const firstClassWeightThreshold = 0.7
export const shippingShopId = 15

export const promoTypeIds = {
  PRODUCT_GROUP: 1,
  REFERRAL: 6,
  SHIPPING_DISCOUNT: 7,
  PRODUCT_GROUP_FREE_CHECKOUT: 11, // used for employee and free item discount
  THREE_FOUR_SAVE: 21
}

export const printTechniques = {
  UNDEFINED: 'Undefined',
  VINYL: 'Vinyl',
  SCREEN: 'Screen',
  PRINTER: 'Printer',
  STICKER: 'Sticker',
  DTG: 'DTG',
  EMBROIDERY: 'Embroidery',
  APPLIQUE: 'Applique',
  NONE: 'None',
  DYESUB: 'DyeSub',
  WALL_ART: 'WallArt',
  DTGEPSON: 'DTGEpson'
}

export const printerMedia = {
  STRECH_PRINT_VP: 'StrechPrintVP',
  SOLUTIONS_OPAQUE: 'SolutionsOpaque',
  MAXIM: 'Maxim',
  ULTIMATE_DARK: 'UltimateDark',
  TURBO_PRINT: 'TurboPrint',
  HARDGOODS: 'Hardgoods',
  APPAREL: 'Apparel',
  BANNER: 'Banner',
  CANVAS: 'Canvas',
  POSTERS: 'Posters',
  WALL_GRAPHICS: 'WallGraphics',
  DIGI_MAG: 'DigiMag',
  BRUSHED_POLY_FLEECE: 'Brushed_Poly_Fleece',
  DTGEPSON: 'DTGEpson',
  PERFORMANCE_POLY: 'Performance_Poly'
}

export const colorLimitations = {
  SHOW_ALL: 0,
  SHOW_ONLY_LISTED: 1,
  EXCLUDE_LISTED: 2
}

export const storeTypes = {
  ADVENTURE: 'adventure',
  COLLEGE: 'college',
  DESTINATION: 'destination',
  GOLF: 'golf',
  GOVERNMENT: 'government',
  GREEK: 'greek',
  LITTLE_LEAGUE: 'littleleague',
  MILITARY: 'military',
  PROSPORTS: 'prosports',
  RACES: 'races',
  SCHOOL: 'school',
  TEAM: 'team',
  VINTAGE_TEAMS: 'vintageteams'
}

export const COUNTRY_US = 'US'
export const COUNTRY_CA = 'CA'
// Temporarily disable shipping to Canada during the November/December 2024 strike
// (USPS is no longer accepting packages for Canada and will reject or return them)
// export const ALLOWED_COUNTRIES = [COUNTRY_US, COUNTRY_CA]
export const ALLOWED_COUNTRIES = [COUNTRY_US]

export const defaults = {
  COUNTRY: COUNTRY_US,
  MAIL_CLASS_US_CODE: shippingMethods.USPS_GROUND_ADVANTAGE,
  MAIL_CLASS_INTL_CODE: shippingMethods.USPS_INTERNATIONAL,
  MAIL_CLASS_US_NAME:
    shippingMethodDataNames[shippingMethods.USPS_GROUND_ADVANTAGE],
  MAIL_CLASS_INTL_NAME:
    shippingMethodDataNames[shippingMethods.USPS_INTERNATIONAL]
}
